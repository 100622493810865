import React from "react";
function Memberships() {
  return (
    <section className="membership">
      <div className="container">
        <h1>MEMBERSHIPS</h1>
        <img src="" className="img-fluid img-responsive membershipimg" />
        <p className="text-center">
          Detailed benefit levels are coming soon. Join our community discord
          and help us build the membership you want!
        </p>
        <div className="row pt-55">
          <div className="col-lg-4 text-center">
            <img
              src="images/Eagle2-(Website-Size).gif"
              className="img-fluid img-responsive"
            />
          </div>
          <div className="col-lg-4 text-center">
            <img
              src="images/Birdie (Website Size).gif"
              className="img-fluid img-responsive"
            />
          </div>
          <div className="col-lg-4 text-center">
            <img
              src="images/Par (Website Size).gif"
              className="img-fluid img-responsive"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
export default Memberships;
