import react, { useContext, useEffect } from "react";
import Web3Context from "./store/Web3-Context";
import Homepage from "./components/Homepage";

function App() {
  const web3Ctx = useContext(Web3Context);
  useEffect(() => {
    web3Ctx.checkIfWalletConnected();
  }, [web3Ctx]);
  return (
    <div>
      <Homepage></Homepage>
    </div>
  );
}

export default App;
