import React from "react";

function Swinging()
{
    return(
        <section className="swining">
        <div className="container text-center">
            <h1><b>JOIN THE STINGERS <span className="green">NFT SOCIETY</span></b> </h1>
                    <div className="row pt-40">
              <div className="">
                <img
                  src="images/Cards Stacked.png" alt="NFT Cards Stacked"
                  className="img-fluid img-responsive"
                />
          </div>
        </div>
            <h6><span className="green">Memberships as an NFT</span></h6>
            <p>Stingers is an alcohol company, a lifestyle brand, and a growing community. We already have our alcohol made,
            our bottles in production, and our plans set in motion. We have been working on this project for over 2 years
            and are finally ready for everyone to join us as we take the next big step, integrating our real-world company into the blockchain using NFTs.

</p>
            <p>Membership will give you access to a range of benefits from discounts on products and merch (including future partnerships),
            all the way to a lifetime subscription of Stingers shots, and we aren't stopping there! We have plans for building a play-to-earn golf game,
            a rewards system to incentivize HODL'ers, and a couple more surprises yet to be announced.

</p>
            <p><span className="green">Join our community, go grab your clubs, and let's build this company together.</span>
            </p>
        </div>
    </section>
    );
}
export default Swinging;