import React from "react";
function Roadmap() {
    return (
        <section className="roadmap">
            <div className="container">
                <h1>ROADMAP</h1>
                {/* <img src="images/vector_11.png" className="img-fluid img-responsive roadmapimg" /> */}
                <div className="row mt-5">
                    <div className="col-lg-1 col-2 text-center">
                        <img src="images/flag (1).png" className="img-fluid img-responsive pt-2" />
                    </div>
                    <div className="col-lg-11 col-10">
                        <h4>SPRING 2022</h4>
                        <p>Initial Stingers Membership NFTs will be available to purchase. Alcohol and bottling production are already underway.</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-1 col-2 text-center">
                        <img src="images/flag (1).png" className="img-fluid img-responsive pt-2" />
                    </div>
                    <div className="col-lg-11 col-10">
                        <h4>SUMMER/FALL 2022</h4>
                        <p>The first boxes of Stingers' alcohol are released and shipped to customers in the USA. Our governance protocol is being developed and deployed.  </p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-1 col-2 text-center">
                        <img src="images/flag (1).png" className="img-fluid img-responsive pt-2" />
                    </div>
                    <div className="col-lg-11 col-10">
                        <h4>2023 & BEYOND</h4>
                        <p>It's 2023 and the Stingers community is expanding while the play-to-earn golf game is in full development.
                            We are now a full-fledged community-driven alcohol brand with a robust selection of products and offerings.
                            Our community will continue to grow and push Stingers to new markets, products, and locations.</p>
                    </div>
                </div>

            </div>
        </section>
    );
}
export default Roadmap;