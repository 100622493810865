import React from "react";
function Country()
{
    return(
        <section className="club">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <h1>OFF THE LINKS</h1>
                     <p>Stingers is developing a free multiplayer play-to-earn golf simulation. The game will be built on Unreal Engine
                     for users to play on all gaming devices. The game will allow the best players in golf to compete with fans worldwide, for an unparalleled experience.
</p>
                    <p>Brands will be able to provide sponsorship by releasing exclusive NFT's for players to customize their avatars' clothes and gear. Existing golf simulators will be able to access the game to give their current users an Unreal experience. The game will have both VR courses and PGA tour courses.
                    </p>
                </div>
                <div className="col-lg-6">
                    <img src="images/Stingers country.png" className="img-fluid img-responsive" />
                </div>
            </div>
        </div>
    </section>
    );
}
export default Country;