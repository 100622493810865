import React, { useContext } from "react";
import Web3Context from "../store/Web3-Context";

function Header() {
  const web3Ctx = useContext(Web3Context);
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="#">
          <img src="images/logo.png" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="https://www.stingersgolf.com/">
                HOME
              </a>
            </li>
            <li className="nav-item ml-3">
              <a className="nav-link" href="#">
                MEMBERSHIPS
              </a>
            </li>
            <li className="navv">
              <a
                className="navbtn"
                onClick={web3Ctx.connectWallet()}
                style={{ cursor: "pointer" }}
              >
                {web3Ctx.walletAddress
                  ? web3Ctx.walletAddress
                  : "CONNECT WALLET"}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Header;
