import React from "react";

function Socialsec() {
    return (
<div className="socialsec">
   <ul>
       <li><img src="images/1.webp" /></li>
       <li><img src="images/2.webp" /></li>
       <li><img src="images/3.webp" /></li>
       <li><img src="images/4.webp" /></li>
       <li><img src="images/5.webp" /></li>
    
      
   </ul>
</div>
    );
}
export default Socialsec;