import React from "react";

function Alcohol()
{
    return(
        <section className="alcohol">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <h1>ALCOHOL RELEASES</h1>
                    <p>Top tier members will receive a <span className="pink">lifetime subscription</span> of Stingers, plus discounts on alcohol and apparel purchased in our Pro Shop.
 </p>
                       <p>Members will have the opportunity to vote and provide
                            feedback on upcoming new releases, flavors, package design, and more!
</p>
                    <p>The initial release will be a Tiger's Blood (strawberry, watermelon, coconut mix) flavored vodka 50ML shooter. We are already working on the release of new shooter flavors, boxed or canned cocktails, and 750 ml bottles.
</p>

                    {/* <u>Additional SKU’s </u>
                    <ul>
                        <li>750ml Bottles</li>
                        <li>canned cocktail</li>
                    </ul> */}
                </div>
                <div className="col-lg-6 text-center">
                    <img src="images/golfball_1.png" className="img-fluid img-responsive" />
                </div>
            </div>
        </div>
    </section>
    );
}
export default Alcohol;