import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Herosection from "./Herosection";
import Swinging from "./Swinging";
import Alcohol from "./Alcohol";
import Country from "./Country";
import Memberships from "./Memberships";
import Lofty from "./Lofty";
import Roadmap from "./Roadmap";
import Faq from "./Faq";
import Learnmore from "./Learnmore";
import Socials from "./Socials";
import Socialsec from "./Socialsec";

function Homepage()
{
    return(
        <>
        <Header></Header>
        <Herosection></Herosection>
        <Swinging></Swinging>
        <Alcohol></Alcohol>
        <Memberships></Memberships>
        <Lofty></Lofty>
        <Country></Country>
        <Roadmap></Roadmap>
        <Faq></Faq>
        <Learnmore></Learnmore>
        <Socials></Socials>
        <Socialsec></Socialsec>
        <Footer></Footer>
        </>
    );

}
export default Homepage;