import React from "react";

function Socials() {
  return (
    <div className="social">
      <div className="container text-center">
        <h5>Swing into our socials.</h5>
          <a href="https://vm.tiktok.com/ZTdHkwS1t/"
          target={"_blank"}
          rel="noreferrer"
        >
          Tik Tok
        </a>
        <a
          href="https://instagram.com/stingersgolf"
          target={"_blank"}
          className="insta"
          rel="noreferrer"
        >
          Instagram
        </a>
        <a
          href="https://twitter.com/stingersgolf"
          target={"_blank"}
          className="insta"
          rel="noreferrer"
        >
          Twitter
        </a>
        <a
          href="https://discord.gg/8sBJ3pBB2w"
          target={"_blank"}
          className="insta"
          rel="noreferrer"
        >
          Discord
        </a>
      </div>
    </div>
  );
}
export default Socials;
