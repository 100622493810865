import React from "react";

function Lofty()
{
    return(
        <section className="lofty">
        <div className="container">
                <div class="wrapper">
                </div>
                <div className="row align-items-center">
                    <div className="text-center">
                        <div class="box box2"><h1>OUR LOFTY GOALS</h1>
                        <p>It’s time to ditch the Prohibition roots of the alcohol industry and start a new era we can all be proud of. Our mission is to create a new generation of alcohol companies that can grow and develop alongside Web3 technology and the blockchain. We will be the first alcohol brand to hit shelves with this technology in place, and we will show the world how vital it is to future growth.
     </p>
                           <p>We have all seen big companies making bad decisions, and known we could do better. We know a driven community has far more experience and knowledge than any single CEO could ever hold. That is why we are dedicated to making a change in this archaic business structure, and allowing your great ideas and life experiences to help shape our future. We want to give you a chance to be genuinely heard and valued for everything you bring to the table, because we believe human capital and word of mouth are the most underrated assets in any company.
    </p>
                        </div>


                    </div>
                </div>
        </div>
    </section>
    );
}
export default Lofty;