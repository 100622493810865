import React from "react";
function Faq() {
    return (
        <section className="faq">
            <div className="container">
                <h1>FAQ</h1>
                <div className="accordin mt-5">
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    What governance rights do NFT members hold?
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Stingers NFT members will help lead the company's direction based on community voting. Each NFT has voting power that can be used to participate in the governance of Stingers. Members will have the ability to vote on the proposal, approval, and execution of matters, including but not limited to:

    <br /><br />
                                    •  New product offerings, flavors, and apparel<br />
                                    •  Club rules, culture, activities, design, and overall vision<br />
                                    •  Marketing, partnerships, sales, and VIP event locations<br />
                                    •  Plus more to be announced soon <br />
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    How will the proceeds be used?
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">The proceeds will be used to fund Stingers' operations including, but not limited to:<br /><br />
                                    • Play-to-earn game development <br />
                                    • Membership program fulfillment<br />
                                    • New product development<br />
                                    • Governance infrastructure setup and fees<br />
                                    • Legal, compliance, and accounting<br />
                                    • Marketing, partnerships, and sales<br />
                                    • Collaborations and community development<br />
                                    • Further initiatives as approved by the Governance members
                                </div>
                            </div>
                        </div>



                        

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                Is there an age requirement to become a member?
                                </button>
                            </h2>
                            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                Yes, all NFT members must be at least 21 years of age.</div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                If I dont want to be a member any more can I sell my NFT?
                                </button>
                            </h2>
                            <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                Absolutely, feel free to sell your NFT on the secondary market (i.e: <a href="https://opensea.io/" target="_blank">Opensea.io</a>) </div>
                            </div>
                        </div>


                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingSix">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                What is an NFT?
                                </button>
                            </h2>
                            <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                Non-Fungible Tokens are unique digital assets users can buy, own and trade. These tokens are stored on a
                                    digital ledger (blockchain) that can not be replicated. Digital NFTs can represent ownership of jpeg art, audio files,
                                    or assets used inside of the Metaverse. Physical or tangible NFTs are linked to assets like real estate, collectibles
                                    and memberships. Stingers NFT owners are linked to the physical membership from the company allowing them
                                    to govern, create value, and contribute to the Defi Spirits ecosystem.</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faq;