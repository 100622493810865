import React from "react";

function Footer()
{
    return(
        <footer className="footer">
        <div className="container text-center">
           <img src="images/footerlogo.webp" />
        </div>

    </footer>
    );
}
export default Footer;