import React from "react";

function Learnmore()
{
    return(
      <div className="learnmorebg">
<div className="container text-center">
    <h6>Want to learn more about the project? View our documents page below and hit us up on our socials. Together we can build the best decentralized alcohol company in the universe.
</h6>
<a href="#" className="mt-5">Coming Soon</a>
</div>
      </div>
    );
}
export default Learnmore;